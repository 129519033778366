<template>
  <div class="h-full">
    <div class="border border-solid d-theme-border-grey-light h-full">
      <div class="w-full flex justify-between" style="background-color: rgba(121,121,121,0.1);">
        <div class="flex items-center justify-start">
          <p class="text-xs font-medium p-2 text-dark text-bold">
            {{ data.loading ? 'Loading...' : 'SPP Termin' }}
          </p>
          <QueryEditor v-if="$can('view_query')" code="TERMIN_SPK_REQUEST_PAYMENT"/>
        </div>
      </div>
      <div class="h-full">
        <div v-if="!selectedHeader" class="w-full h-full flex justify-center items-center">
          <p class="text-xs">Pilih transaksi disebelah kiri untuk melihat data.</p>
        </div>
        <div v-else class="overflow-auto">
          <table class="w-full h-full border-collapse table-fixed">
            <thead class="font-extrabold border border-solid d-theme-border-grey-light">
              <tr class="d-theme-dark-bg border border-solid d-theme-border-grey-light">
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-16">Action</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-20">Termin</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-24">Status</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-20">Status Approval</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-20">Retensi</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-24">Bobot Harus Tercapai</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-24">Pembayaran</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-32">Nilai Awal</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-32">Nilai Pengurangan (Adjust)</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-32">Balance</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-24">File Pengajuan</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-24">Approval Progress</th>
              </tr>
            </thead>
            <tbody class="font-medium">
              <tr v-for="(item, index) in data.items" :key="index" class="cursor-pointer hover:font-medium">
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">
                  <div class="flex items-center space-x-2">
                    <vs-button v-if="item.is_show_button_claim" class="py-2 px-3 small" type="filled" @click="showModalClaimPayment(item)" :disabled="!item.is_claimable">Claim</vs-button>
                  </div>
                </td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.no_termin }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">
                  <div class="flex items-center">
                    <div class="text-white rounded-sm text-center items-center px-1 rounded" :class="'bg-' + item.status_color">
                      <span class="text-xs whitespace-no-wrap">{{ item.status }}</span>
                    </div>
                  </div>
                </td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center">{{ item.status_approval ? item.status_approval : '-'  }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.is_retention ? 'YES' : '-' }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total_bobot_harus_tercapai }}%</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total_persen_pembayaran }}%</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.jml_total | idr }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.total_nilai_adjust | idr }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-right">{{ item.balance | idr }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">
                  <span v-if="item.files_url.length > 0" class="text-xs underline" @click="showFiles(item)">Lihat Files</span>
                  <span v-else class="text-sm">-</span>
                </td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">
                  <span v-if="item.status_approval" class="text-xs underline" @click="showModalApproval(item)">Lihat</span>
                  <span v-else class="text-sm">-</span>
                </td>
              </tr>
              <!--empty state-->
              <tr v-if="data.items.length < 1">
                <td colspan="8" class="text-xs text-center p-2">Tidak ada data.</td>
              </tr>
              <!--spacer-->
              <tr v-if="data.items.length > 0" class="h-full">
                <td colspan="8"></td>
              </tr>
            </tbody>
            <tfoot>
              <tr class="d-theme-dark-bg">
                <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-xs font-bold text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-xs font-bold text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-xs font-bold text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-xs font-bold text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-xs font-bold text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-xs font-bold text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-xs font-bold text-right">{{ summary.grandTotalPersenPembayaran }}%</th>
                <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-xs font-bold text-right">{{ summary.grandTotalNilai | idr }}</th>
                <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-xs font-bold text-right">{{ summary.grandTotalNilaiAdjust | idr }}</th>
                <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-xs font-bold text-right">{{ summary.grandTotalBalance | idr }}</th>
                <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-xs font-bold text-right"></th>
                <th class="border border-solid d-theme-border-grey-light p-2 whitespace-no-wrap text-xs font-bold text-right"></th>
              </tr>
            </tfoot>
          </table>
        </div>
      </div>
    </div>

    <!--modals-->
    <ClaimPayment :isActive.sync="modalClaimPayment.active" :termin="modalClaimPayment.termin" @success="onSuccessClaimPayment"/>
    <FilesViewer :isActive.sync="modalFiles.active" :filesUrl="modalFiles.filesUrl"/>
    <ModalApprovalProgress :isActive.sync="modalApproval.active" :type="modalApproval.type" :id-ref="modalApproval.idRef"/>
  </div>
</template>

<script>
import SpkRequestPaymentRepository from '@/repositories/proyek/spk-request-payment-repository'
import { v4 as uuid } from 'uuid'
import _ from 'lodash'

export default {
  name: 'TerminSection',
  components: {
    QueryEditor: () => import('@/views/components/query-editor/QueryEditor'),
    ClaimPayment: () => import('@/views/pages/proyek/spk-request-payment/termin/ClaimPayment'),
    FilesViewer: () => import('@/views/components/files-viewer/FilesViewer'),
    ModalApprovalProgress: () => import('@/views/components/approval-progress/ModalApprovalProgress')
  },
  computed: {
    dataFilterSection () {
      return this.$store.state.proyek.spkRequestPayment.filterSection
    },
    dataHeaderSection () {
      return this.$store.state.proyek.spkRequestPayment.headerSection
    },
    selectedHeader () {
      return this.dataHeaderSection.selected
    },
    summary () {
      return {
        grandTotalPersenPembayaran: _.sumBy(this.data.items, item => item.total_persen_pembayaran),
        grandTotalNilai: _.sumBy(this.data.items, item => item.jml_total),
        grandTotalNilaiAdjust: _.sumBy(this.data.items, item => item.total_nilai_adjust),
        grandTotalBalance: _.sumBy(this.data.items, item => item.balance)
      }
    }
  },
  data () {
    return {
      data: {
        loading: false,
        items: []
      },
      modalApproval: {
        active: false,
        type: null,
        idRef: null
      },
      modalClaimPayment: {
        termin: {},
        active: false
      },
      modalFiles: {
        filesUrl: [],
        active: false
      }
    }
  },
  watch: {
    selectedHeader (newVal, oldVal) {
      const newId = newVal ? newVal.id : null
      const oldId = oldVal ? oldVal.id : null
      if (!_.isEmpty(newVal) && !_.isEqual(newId, oldId)) {
        this.refresh()
      }
    },
    data: {
      deep: true,
      handler (value) {
        this.$store.commit('proyek/spkRequestPayment/SET_TERMIN_SECTION', _.cloneDeep(value))
      }
    }
  },
  methods: {
    getData () {
      this.data.loading = true

      const params = {
        id_spk: this.selectedHeader.id
      }
      SpkRequestPaymentRepository.getTermins(params)
        .then(response => {
          this.data.items = _.map(response.data.data, item => {
            item.uuid = uuid()
            return item
          })
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.data.loading = false
        })
    },

    showFiles (item) {
      this.modalFiles.filesUrl = item.files_url
      this.modalFiles.active = true
    },

    showModalApproval (item) {
      this.modalApproval.type = 'SPP-TERMIN'
      this.modalApproval.idRef = item.id
      this.modalApproval.active = true
    },

    showModalClaimPayment (termin) {
      this.modalClaimPayment.termin = termin
      this.modalClaimPayment.active = true
    },

    onSuccessClaimPayment () {
      this.refresh()
      this.emitUpdated()
      this.modalClaimPayment.active = false
    },

    refresh () {
      this.data.items = []
      this.getData()
    },

    emitUpdated () {
      this.$emit('updated', true)
    }
  }
}
</script>
