const getDefaultState = () => {
  return {
    filterSection: {},
    headerSection: {
      items: []
    },
    terminSection: {
      items: []
    }
  }
}

export default {
  namespaced: true,

  state: getDefaultState(),

  getters: {},

  mutations: {
    SET_FILTER_SECTION (state, payload) {
      state.filterSection = payload
    },
    SET_HEADER_SECTION (state, payload) {
      state.headerSection = payload
    },
    SET_TERMIN_SECTION (state, payload) {
      state.terminSection = payload
    },
    RESET_STATE (state) {
      Object.assign(state, getDefaultState())
    }
  },

  actions: {}
}
