<template>
  <div class="h-full">
    <div class="border border-solid d-theme-border-grey-light h-full">
      <div class="w-full flex justify-between" style="background-color: rgba(121,121,121,0.1);">
        <div class="flex items-center justify-start">
          <p class="text-xs font-medium p-2 text-dark text-bold">
            {{ data.loading ? 'Loading...' : 'SPP Header' }}
          </p>
          <QueryEditor v-if="$can('view_query')" code="HEADER_SPK_REQUEST_PAYMENT"/>
        </div>
      </div>
      <div class="h-full">
        <div class="tableSticky overflow-auto">
          <table class="w-full h-full border-collapse table-fixed">
            <thead class="font-bold">
              <tr class="d-theme-dark-bg">
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-4"></th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-10">#</th>
                <th class="text-xs px-6 py-1 border border-solid d-theme-border-grey-light text-center w-48">No SPP</th>
                <th class="text-xs px-6 py-1 border border-solid d-theme-border-grey-light text-center w-32">Jenis SPP</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-24">Bobot Tercapai</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-24">Pekerjaan Selesai</th>
                <th class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-center w-24">Termin Diajukan</th>
                <th class="text-xs px-6 py-1 border border-solid d-theme-border-grey-light text-center w-64">Nama Rekanan</th>
              </tr>
            </thead>
            <tbody class="font-medium">
              <tr v-for="(item, index) in data.items" :key="index" class="cursor-pointer hover:font-medium" @click="data.selected = item">
                <td class="text-xx border border-solid d-theme-border-grey-light text-center">
                  <div class="flex items-center">
                    <vs-icon v-if="item === data.selected" size="14px" color="danger" icon-pack="material-icons" icon="play_arrow"/>
                  </div>
                </td>
                <td class="text-xs px-6 py-1 border border-solid d-theme-border-grey-light text-left">{{ index + 1 }}</td>
                <td class="text-xs px-6 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.no_spk }}</td>
                <td class="text-xs px-6 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.jenis_spk }}</td>
                <td class="text-xs px-6 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.total_bobot_tercapai | idr }}%</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.total_pekerjaan_selesai }} dari {{ item.total_pekerjaan }}</td>
                <td class="text-xs px-2 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.total_termin_diajukan }} dari {{ item.total_termin }}</td>
                <td class="text-xs px-6 py-1 border border-solid d-theme-border-grey-light text-left">{{ item.nama_rekanan }}</td>
              </tr>
              <!--empty state-->
              <tr v-if="data.items.length < 1">
                <td colspan="7" class="text-xs text-center p-2">Tidak ada data.</td>
              </tr>
              <!--spacer-->
              <tr v-if="data.items.length > 0" class="h-full">
                <td v-if="data.isAnyDataLeft" colspan="7" @click="loadMore" class="px-2 py-3 cursor-pointer text-left text-sm hover:text-primary">
                  {{ data.loading ? 'Loading...' : 'Load More' }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SpkRequestPaymentRepository from '@/repositories/proyek/spk-request-payment-repository'
import _ from 'lodash'

export default {
  name: 'HeaderSection',
  components: {
    QueryEditor: () => import('@/views/components/query-editor/QueryEditor')
  },
  computed: {
    dataFilterSection () {
      return this.$store.state.proyek.spkRequestPayment.filterSection
    }
  },
  data () {
    return {
      data: {
        page: 1,
        limit: 50,
        isAnyDataLeft: true,
        loading: false,
        selected: null,
        items: []
      }
    }
  },
  watch: {
    data: {
      deep: true,
      handler (value) {
        this.$store.commit('proyek/spkRequestPayment/SET_HEADER_SECTION', _.cloneDeep(value))
      }
    }
  },
  methods: {
    getData (isReplaceData = false) {
      this.data.loading = true

      const params = {
        id_proyek: this.isRekanan ? null : this.dataFilterSection.id_proyek,
        id_rekanan: this.isRekanan ? this.$store.state.auth.user.rekanan.id : null,
        search: this.dataFilterSection.search,
        page: this.data.page,
        limit: this.data.limit
      }
      SpkRequestPaymentRepository.getHeaders(params)
        .then(response => {
          const items = response.data.data
          if (isReplaceData) {
            this.data.items = items
          } else {
            _.each(items, item => this.data.items.push(item))
          }

          if (items.length < this.data.limit) {
            this.data.isAnyDataLeft = false
          }
        })
        .catch(error => {
          console.log(error)
          this.notifyError('Terjadi kesalahan.')
        })
        .finally(() => {
          this.data.loading = false
        })
    },

    loadMore () {
      this.data.page++
      this.onPageChange()
    },

    onPageChange () {
      this.getData()
    },

    refresh (isReplaceData = false) {
      this.data.page = 1
      this.data.isAnyDataLeft = true
      this.data.items = []
      this.data.selected = null
      this.getData(isReplaceData)
    }
  }
}
</script>

<style scoped>
.tableSticky {
  overflow: auto;
  height: calc(100vh - 245px);
}

.tableSticky thead tr {
  position: sticky;
  top: -1px;
  z-index: 1;
}

.tableSticky tfoot tr {
  position: sticky;
  bottom: -1px;
  z-index: 1;
}
</style>
