<template>
  <div class="flex flex-col no-scroll-content">
    <!------------ filter ------------->
    <div class="flex-none">
      <div class="p-2 vx-card shadow-none rounded-none border-solid d-theme-border-grey-light border border-b-0">
        <div class="flex flex-wrap">
          <div class="px-2 sm:w-3/12 w-full" v-if="!isRekanan">
            <div class="flex items-center text-xs">
              <label class="mr-1 text-xs whitespace-no-wrap">Proyek</label>
              <vs-select class="w-full vs-select-small" v-model="filterSection.id_proyek">
                <vs-select-item class="vs-select-small" :value="null" text="Semua"/>
                <vs-select-item class="vs-select-small" v-for="(it, index) in proyeks" :key="index" :value="it.id" :text="`${it.kode} - ${it.nama}`"/>
              </vs-select>
            </div>
          </div>
          <div class="flex items-center">
            <vs-input class="w-full" type="text" placeholder="Search" v-model="filterSection.search" @keyup.enter="filter"/>
          </div>
          <div class="px-2 sm:w-1/12 w-full">
            <vs-button class="px-6 py-3 text-xs text-center" color="primary" @click="filter">Filter</vs-button>
          </div>
        </div>
      </div>
    </div>

    <!----------- content ------------->
    <div class="flex-grow">
      <div class="flex h-full">
        <div class="w-full sm:w-4/12 mr-1 d-theme-dark-bg">
          <HeaderSection ref="headerSection"/>
        </div>
        <div class="w-full sm:w-8/12 ml-1 d-theme-dark-bg">
          <TerminSection ref="terminSection"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ProyekRepository from '@/repositories/master/proyek-repository'
import HeaderSection from '@/views/pages/proyek/spk-request-payment/header/HeaderSection'
import TerminSection from '@/views/pages/proyek/spk-request-payment/termin/TerminSection'
import moduleSpkRequestPayment from '@/store/modules/proyek/spk-request-payment/spk-request-payment.store'
import _ from 'lodash'

export default {
  name: 'SpkRequestPayment',
  components: {
    HeaderSection,
    TerminSection
  },
  mounted () {
    this.getProyek()
    this.filter()
  },
  computed: {
    dataHeaderSection () {
      return this.$store.state.proyek.spkRequestPayment.headerSection
    },
    dataTerminSection () {
      return this.$store.state.proyek.spkRequestPayment.terminSection
    }
  },
  data () {
    return {
      filterSection: {
        id_proyek: null,
        search: null
      },
      proyeks: []
    }
  },
  watch: {
    filterSection: {
      deep: true,
      handler (value) {
        this.commitFilterSectionToStore(value)
      }
    }
  },
  methods: {
    getProyek () {
      const params = {
        filter: JSON.stringify({
          only_authorized_user: 1
        })
      }
      ProyekRepository.getAll(params)
        .then(response => {
          this.proyeks = response.data.data
        })
        .catch(error => {
          console.log(error)
        })
    },

    filter () {
      this.$refs.headerSection.refresh(true)
    },

    commitFilterSectionToStore (value) {
      this.$store.commit('proyek/spkRequestPayment/SET_FILTER_SECTION', _.cloneDeep(value))
    }
  },
  created () {
    this.commitFilterSectionToStore(this.filterSection)
  },
  beforeCreate () {
    this.$store.registerModule(['proyek', 'spkRequestPayment'], moduleSpkRequestPayment)
  },
  beforeDestroy () {
    this.$store.commit('proyek/spkRequestPayment/RESET_STATE')
    this.$store.unregisterModule(['proyek', 'spkRequestPayment'])
  }
}
</script>
